import { AlternativeProductLinks, Website } from './language-switcher-types'

export const combineLanguageSwitcherData = (
  websites: Website[],
  productLinks: AlternativeProductLinks[],
  isGeneralMarket: boolean,
): Website[] => {
  return websites.map((website) => {
    const matchingLink = productLinks.find((link) => {
      const [language, linkMarket] = link?.hreflang?.split('-') || []

      // for UA, compare languages
      if (!isGeneralMarket) {
        return language === website.countryCode
      }
      // exclude Russian links on COM
      if (isGeneralMarket && language === 'ru') {
        return false
      }
      return linkMarket === website.language
    })

    return matchingLink
      ? {
          ...website,
          productUrl: matchingLink?.href,
        }
      : website
  })
}
