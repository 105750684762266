import { useMemo } from 'react'

import { combineLanguageSwitcherData } from './combine-language-switcher-data'
import { AlternativeProductLinks, Website } from './language-switcher-types'

export const useAlternativeLanguageWebsites = ({
  websites,
  storeCode,
  alternativeProductLinks,
  isGeneralMarket,
}: {
  websites: Website[]
  storeCode: string
  alternativeProductLinks: AlternativeProductLinks[]
  isGeneralMarket: boolean
}): {
  languageSwitcherData: Website[]
  currentWebsite?: Website
} => {
  const languageSwitcherData = useMemo(() => {
    const alternativeWebsites = websites.filter(
      (website) => website.storeCode !== storeCode,
    )

    return combineLanguageSwitcherData(
      alternativeWebsites,
      alternativeProductLinks,
      isGeneralMarket,
    )
  }, [websites, storeCode, alternativeProductLinks, isGeneralMarket])

  const currentWebsite = websites.find(
    (website) => website.storeCode === storeCode,
  )

  return { currentWebsite, languageSwitcherData }
}
