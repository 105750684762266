import { StoreCodeType } from '../types'

export const storeCodesWithSwitcher: StoreCodeType[] = [
  'gymbeamcom',
  'ru',
  'ua',
]

export const storeCodesSpaEnabled: StoreCodeType[] = [
  'gymbeamcom',
  'gymbeamba',
  'gymbeamrs',
]
