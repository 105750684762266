import { FC, SVGAttributes } from 'react'

import { default as At } from './at'
import { default as Ba } from './ba'
import { default as Bg } from './bg'
import { default as Cz } from './cz'
import { default as De } from './de'
import { default as Gb } from './gb'
import { default as Gr } from './gr'
import { default as Hr } from './hr'
import { default as Hu } from './hu'
import { default as It } from './it'
import { default as Pl } from './pl'
import { default as Ro } from './ro'
import { default as Rs } from './rs'
import { default as Ru } from './ru'
import { default as Si } from './si'
import { default as Sk } from './sk'
import { default as Ua } from './ua'

const FLAGS: Record<string, FC<SVGAttributes<SVGSVGElement>>> = {
  at: At,
  ba: Ba,
  bg: Bg,
  cz: Cz,
  de: De,
  gb: Gb,
  gr: Gr,
  hr: Hr,
  hu: Hu,
  it: It,
  pl: Pl,
  ro: Ro,
  rs: Rs,
  ru: Ru,
  si: Si,
  sk: Sk,
  ua: Ua,
}

interface FlagProps extends SVGAttributes<SVGSVGElement> {
  icon?: string | null
}

export const CountryFlag = ({ icon, ...rest }: FlagProps) => {
  const FlagComponent = icon && FLAGS[icon]

  if (FlagComponent) {
    return <FlagComponent {...rest} />
  } else {
    return null
  }
}
