import { Website } from './language-switcher-types'

export const COM_WEBSITES: Website[] = [
  {
    icon: 'sk',
    language: 'sk',
    url: 'https://gymbeam.sk',
    name: 'Slovakia',
    storeCode: 'gymbeamsk',
  },
  {
    icon: 'cz',
    language: 'cz',
    url: 'https://gymbeam.cz',
    name: 'Czechia ',
    storeCode: 'gymbeamcz',
  },
  {
    icon: 'hu',
    language: 'hu',
    url: 'https://gymbeam.hu',
    name: 'Hungary',
    storeCode: 'gymbeamhu',
  },
  {
    icon: 'ro',
    language: 'ro',
    url: 'https://gymbeam.ro',
    name: 'Romania',
    storeCode: 'gymbeamro',
  },
  {
    icon: 'de',
    language: 'de',
    url: 'https://gymbeam.de',
    name: 'Germany',
    storeCode: 'gymbeamde',
  },
  {
    icon: 'hr',
    language: 'hr',
    url: 'https://gymbeam.hr',
    name: 'Croatia',
    storeCode: 'gymbeamhr',
  },
  {
    icon: 'bg',
    language: 'bg',
    url: 'https://gymbeam.bg',
    name: 'Bulgaria',
    storeCode: 'gymbeambg',
  },
  {
    icon: 'pl',
    language: 'pl',
    url: 'https://gymbeam.pl',
    name: 'Poland',
    storeCode: 'gymbeampl',
  },
  {
    icon: 'si',
    language: 'si',
    url: 'https://gymbeam.si',
    name: 'Slovenia',
    storeCode: 'gymbeamsi',
  },
  {
    icon: 'ba',
    language: 'ba',
    url: 'https://gymbeam.ba',
    name: 'Bosnia & Herzegovina ',
    storeCode: 'gymbeamba',
  },
  {
    icon: 'ua',
    language: 'ua',
    url: 'https://gymbeam.ua',
    name: 'Ukraine',
    storeCode: 'ua',
  },
  {
    icon: 'rs',
    language: 'rs',
    url: 'https://gymbeam.rs',
    name: 'Serbia ',
    storeCode: 'gymbeamrs',
  },
  {
    icon: 'gr',
    language: 'gr',
    url: 'https://gymbeam.gr',
    name: 'Greece',
    storeCode: 'gymbeamgr',
  },
  {
    icon: 'it',
    language: 'it',
    url: 'https://gymbeam.it',
    name: 'Italy',
    storeCode: 'gymbeamit',
  },
  {
    icon: null,
    language: 'en',
    url: 'https://gymbeam.com',
    name: 'Com',
    storeCode: 'gymbeamcom',
  },
]

export const UA_WEBSITES: Website[] = [
  {
    icon: null,
    language: 'ru',
    countryCode: 'ru',
    url: 'https://gymbeam.ua/ru',
    name: 'Pосійська',
    storeCode: 'ru',
  },
  {
    icon: 'ua',
    language: 'ua',
    countryCode: 'uk',
    url: 'https://gymbeam.ua/ua',
    name: 'Украина',
    storeCode: 'ua',
  },
]
