'use client'

import React from 'react'
import { twMerge } from 'tailwind-merge'

import { ArrowSimpleLeftIcon } from '@/components/icons/svg'
import { Popup } from '@/components/popup'
import { LanguageSwitcherPopupMenu } from './language-switcher-popup-menu'
import {
  AlternativeProductLinks,
  LanguageSwitcherProps,
} from './language-switcher-types'
import { COM_WEBSITES, UA_WEBSITES } from './language-switcher-websites'
import { StoreNavigator } from '@/components/store-navigator'
import { storeCodesWithSwitcher } from '@/common/constants/store-constants'
import { useAlternativeLanguageWebsites } from './use-alternative-language-websites'

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  storeCode,
  alternativeProductLinks,
}: {
  storeCode: string
  alternativeProductLinks: AlternativeProductLinks[]
}) => {
  const websites = storeCode === 'gymbeamcom' ? COM_WEBSITES : UA_WEBSITES
  const isGeneralMarket = storeCode === 'gymbeamcom'
  const displaySwitcher = storeCodesWithSwitcher.some(
    (code) => code === storeCode,
  )

  const { languageSwitcherData, currentWebsite } =
    useAlternativeLanguageWebsites({
      websites,
      alternativeProductLinks,
      isGeneralMarket,
      storeCode,
    })

  return (
    <>
      {isGeneralMarket && (
        <StoreNavigator
          websites={languageSwitcherData}
          currentWebsiteUrl={currentWebsite?.productUrl ?? currentWebsite?.url}
        />
      )}
      {displaySwitcher && (
        <div className="bg-primary text-white min-w-full">
          <div className="container max-w-[940px] mx-auto flex justify-end">
            <Popup
              content={
                <LanguageSwitcherPopupMenu
                  alternativeWebsites={languageSwitcherData}
                />
              }
            >
              {({ isOpen }) => (
                <strong
                  className={twMerge(
                    'inline-flex items-center cursor-pointer',
                    'p-4 px-1',
                    'uppercase',
                  )}
                >
                  {currentWebsite?.language}{' '}
                  <ArrowSimpleLeftIcon
                    className={twMerge(
                      'inline-flex fill-current ml-1 w-4 h-4 ',
                      isOpen ? 'rotate-90' : '-rotate-90',
                    )}
                  />
                </strong>
              )}
            </Popup>
          </div>
        </div>
      )}
    </>
  )
}
