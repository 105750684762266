import { useEffect, useState } from 'react'

import { GeoLocationData, getGeoLocation } from '@/api'
import { Website } from '../header/components/language-switcher/language-switcher-types'

export const useGeoLocation = ({
  websites,
  setIsModalShown,
  currentWebsiteUrl,
}: {
  websites: Website[]
  setIsModalShown: (value: boolean) => void
  currentWebsiteUrl?: string
}): {
  chosenLocationUrl: string
  geoLocationData?: GeoLocationData
  setChosenLocationUrl: (value: string) => void
} => {
  const [chosenLocationUrl, setChosenLocationUrl] = useState(
    currentWebsiteUrl ?? '',
  )
  const [geoLocationData, setGeoLocationData] = useState<GeoLocationData>()

  useEffect(() => {
    getGeoLocation().then((response) => {
      const [data] = response.data ?? []
      const timestamp = localStorage.getItem('gb-geo-location')
      const hasRecommendedStore = !!data?.recommended_store
      const recommendedUrl =
        data?.recommended_stores?.[0]?.url?.replace(/\/$/, '') ?? ''

      if (hasRecommendedStore) {
        setGeoLocationData(data)

        if (recommendedUrl) {
          const locationUrl = websites?.find(
            (website) => website.productUrl?.indexOf(recommendedUrl) !== -1,
          )

          setChosenLocationUrl(locationUrl?.productUrl ?? recommendedUrl)
        }

        if (!timestamp || +timestamp < Date.now()) {
          setIsModalShown(hasRecommendedStore)
        }
      }
    })
  }, [websites, setIsModalShown])

  return {
    chosenLocationUrl,
    geoLocationData,
    setChosenLocationUrl,
  }
}
