import React from 'react'
import { twJoin } from 'tailwind-merge'

import { CountryFlag } from '@/components/icons/flags'
import { LanguageSwitcherPopupMenuProps } from './language-switcher-types'

export const LanguageSwitcherPopupMenu: React.FC<
  LanguageSwitcherPopupMenuProps
> = ({ alternativeWebsites }) => (
  <div className="text-black p-2.5">
    {alternativeWebsites?.map((item) => {
      return (
        <a
          href={item.productUrl ?? item.url}
          key={item.name}
          className="group flex min-w-max p-0.5 hover:text-secondary items-center cursor-pointer"
        >
          <mark
            className={twJoin(
              'flex flex-wrap content-around justify-around',
              'w-5 h-5',
              'bg-white ',
              'border-2 border-black rounded-full',
              'mr-4',
            )}
          >
            <em className="w-2 h-2 rounded-full group-hover:bg-secondary transition duration-500" />
          </mark>
          <CountryFlag icon={item.icon} className="w-7 h-auto" />
          <span className="pl-3 text-sm">{item.name}</span>
        </a>
      )
    })}
  </div>
)
